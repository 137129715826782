import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Button, ButtonType } from '@admin/atoms/Button';
import { TeamVsTeam } from '@admin/atoms/TeamVsTeam';
import { dataSourceEnvelope } from '@admin/molecules/AutoComplete/AutoComplete';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import {
    BaseModal,
    BaseModalFooter,
    BaseModalHeader,
    BaseModalType,
    useModalContext,
} from '@admin/organisms/BaseModal';
import { Form } from '@admin/organisms/Form';
import FlashMessageUtil from '@admin/utils/FlashMessageUtil/FlashMessageUtil';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Match } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import { formFields, getMatches } from './helpers';
import { MatchDetailSectionNodeData } from '.';

export enum Actions {
    create = 'create',
    update = 'update',
}

interface Props {
    formData?: MatchDetailSectionNodeData;
    onSubmit: (data: MatchDetailSectionNodeData) => void;
    action?: Actions;
}

export const MatchDetailSectionModal = ({ formData, onSubmit, action = Actions.create }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const { closeModal } = useModalContext(BaseModalType.Create);
    const contextData = useContextData();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const flashes = FlashMessageUtil(__translate);
    const mapMatchesToSourceEnvelope = (match: Match) =>
        ({
            text: match.id,
            element: (
                <TeamVsTeam
                    kickOff={match.kickOff}
                    awayTeamName={match.away?.name}
                    homeTeamName={match.home?.name}
                />
            ),
        }) as dataSourceEnvelope;

    const dataSource = async (searchText: string): Promise<dataSourceEnvelope[]> =>
        getMatches(contextData, searchText).then((matches = []) => matches.map(mapMatchesToSourceEnvelope));

    const handleSubmit = async (data: MatchDetailSectionNodeData) => {
        if (!formData?.matchID && !data.matchID) {
            setIsSubmitting(false);
            flashes.customError('insertMatchDetailSection.missingMatchID');
            return;
        }

        const nodeData: MatchDetailSectionNodeData = {
            matchID: data.matchID || formData?.matchID || '',
            showOdds: data.showOdds || false,
            type: CustomNodeTypes.matchDetailSection,
        };

        onSubmit(nodeData);
        setIsSubmitting(false);
        closeModal();
    };

    const clickButton = () => {
        setIsSubmitting(true);
        doSubmit();
    };

    let doSubmit: () => void;
    const bindDoSubmit = (callback: () => void) => {
        doSubmit = callback;
    };

    return (
        <div data-theme="admin">
            <BaseModal contextKey={BaseModalType.Create}>
                <BaseModalHeader contextKey={BaseModalType.Create}>
                    {__translate('insertMatchDetailSection.title')}
                </BaseModalHeader>
                <Form<MatchDetailSectionNodeData, MatchDetailSectionNodeData>
                    id={'match-detail-section'}
                    action={`match-detail-section-${action}`}
                    handleSubmit={handleSubmit}
                    bindDoSubmit={bindDoSubmit}
                    formFields={formFields(dataSource)}
                    entity={formData}
                />
                <BaseModalFooter className={'flex justify-between'}>
                    <Button
                        className={'w-full'}
                        filled={true}
                        loading={isSubmitting}
                        type={ButtonType.button}
                        onClick={clickButton}
                    >
                        {__translate(`insertMatchDetailSection.${action}`)}
                    </Button>
                </BaseModalFooter>
            </BaseModal>
        </div>
    );
};
