import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
} from 'lexical';

import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';

import { MatchDetailSectionNode, MatchDetailSectionNodeData } from './MatchDetailSectionNode';

export const INSERT_MATCH_DETAIL_SECTION_COMMAND: LexicalCommand<string> = createCommand();

export const MatchDetailSectionPlugin = (): null => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([MatchDetailSectionNode])) {
            throw new Error('MatchDetailSectionPlugin: MatchDetailSection not registered on editor');
        }

        return editor.registerCommand<MatchDetailSectionNodeData>(
            INSERT_MATCH_DETAIL_SECTION_COMMAND,
            (data) => {
                const matchDetailSectionNode = MatchDetailSectionNode.__createNode({
                    ...data,
                    type: CustomNodeTypes.matchDetailSection,
                });
                const paragraph = $createParagraphNode();

                $insertNodes([matchDetailSectionNode, paragraph]);
                return true;
            },
            COMMAND_PRIORITY_EDITOR,
        );
    }, [editor]);

    return null;
};
