import { MouseEvent, ReactNode } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Button } from '@admin/atoms/Button/Button';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { Divider } from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon/Icon';

import styles from './ArticleBlock.module.scss';

interface Props {
    children: ReactNode;
    customNodeType: CustomNodeTypes;
    onClick?: (e: MouseEvent) => void;
    onDelete?: (e: MouseEvent) => void;
}

export const ArticleBlock = ({ children, customNodeType, onClick, onDelete }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    return (
        <div className={styles.ArticleBlock}>
            <div></div>
            <div className={styles.content}>
                <h6>----------- {__translate(`articleBlock.title.${customNodeType}`)} -----------</h6>
                <p>{__translate(`articleBlock.subTitle.${customNodeType}`)}</p>
                {children}
            </div>
            <div className={styles.buttonGroupContainer}>
                <div className={styles.buttonGroup}>
                    {onClick ? (
                        <Button className={styles.button} onClick={onClick}>
                            <Icon.edit />
                        </Button>
                    ) : null}
                    {onDelete ? (
                        <>
                            <Divider />
                            <Button className={styles.button} onClick={onDelete}>
                                <Icon.trash />
                            </Button>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
