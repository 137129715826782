import useTranslation from 'next-translate/useTranslation';

import { BaseModalType, useModalContext } from '@admin/organisms/BaseModal';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';

import styles from './InsertMatchDetailSection.module.scss';

interface Props {
    onClick: () => void;
}

export const InsertMatchDetailSection = ({ onClick = () => {} }: Props) => {
    const { openModal } = useModalContext(BaseModalType.Create);
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const handleOnClick = () => {
        openModal();
        onClick();
    };

    return (
        <button
            onClick={handleOnClick}
            className={styles.InsertMatchDetailSection}
            aria-label={__translate('insertMatchDetailSection.button')}
        >
            <Icon.football />
            <span>{__translate('insertMatchDetailSection.button')}</span>
        </button>
    );
};
