import { Suspense } from 'react';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { $generateHtmlFromNodes } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TRANSFORMERS } from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { EditorState, LexicalEditor, TextNode } from 'lexical';
import useTranslation from 'next-translate/useTranslation';

import { DropdownProvider } from '@admin/molecules/RichEditor/Editor/atoms';
import { InlineToolbarPlugin } from '@admin/molecules/RichEditor/Editor/plugins/InlineToolbarPlugin';
import { PostNode } from '@admin/molecules/RichEditor/Editor/plugins/XPlugin/PostNode';
import { XPlugin } from '@admin/molecules/RichEditor/Editor/plugins/XPlugin/XPlugin';
import { TranslationKey } from '@admin/utils/TranslationKey';

import { ExtendedTextNode } from './plugins/ExtendedTextNodePlugin';
import { UndoRedo } from './plugins/ToolbarPlugin/atoms';
import { BlockTypeProvider } from './providers/BlockTypeProvider';
import {
    AutoLinkPlugin,
    CodeHighlightPlugin,
    DefaultValuePlugin,
    MatchDetailSectionNode,
    MatchDetailSectionPlugin,
    OnBlurPlugin,
    RemoveOnBackspacePlugin,
    RichTextWrapperPlugin,
    ToolbarPlugin,
} from './plugins';
import { theme } from './theme';

import styles from './Editor.module.scss';

interface Props {
    onChange: (value: string) => void;
    onBlur?: (value: string) => void;
    value?: string;
}

export const Editor = ({ onChange, onBlur, value }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const editorConfig: InitialConfigType = {
        namespace: 'RichEditor',
        nodes: [
            MatchDetailSectionNode,
            HeadingNode,
            ExtendedTextNode,
            {
                replace: TextNode,
                with: (node: TextNode) => new ExtendedTextNode(node.__text),
                withKlass: ExtendedTextNode,
            },
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            PostNode,
        ],
        onError(error: Error) {
            throw error;
        },
        theme,
    };

    const handleOnChange = (editorState: EditorState, editor: LexicalEditor, _tags: Set<string>) => {
        editorState.read(() => {
            const htmlString = $generateHtmlFromNodes(editor, null);
            onChange(htmlString);
        });
    };

    return (
        <Suspense fallback={<>Loading...</>}>
            <LexicalComposer initialConfig={editorConfig}>
                <div className={styles.Editor}>
                    <div className={styles.header}>
                        <div className={styles.label}>{__translate('label')}</div>

                        <UndoRedo />
                    </div>
                    <BlockTypeProvider>
                        <div className={styles.container}>
                            <ToolbarPlugin />
                            <DropdownProvider>
                                <InlineToolbarPlugin />
                            </DropdownProvider>

                            <div className={styles.inner}>
                                <AutoFocusPlugin />
                                <AutoLinkPlugin />
                                <CodeHighlightPlugin />
                                <DefaultValuePlugin value={value} />
                                <HistoryPlugin />
                                <LinkPlugin />
                                <ListPlugin />
                                <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                                <MatchDetailSectionPlugin />
                                <OnBlurPlugin onBlur={onBlur} />
                                <OnChangePlugin onChange={handleOnChange} />
                                <RemoveOnBackspacePlugin />
                                <RichTextWrapperPlugin />
                                <XPlugin />
                            </div>
                        </div>
                    </BlockTypeProvider>
                </div>
            </LexicalComposer>
        </Suspense>
    );
};
