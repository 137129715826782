import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
} from 'lexical';

import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { PostNode, PostNodeData } from '@admin/molecules/RichEditor/Editor/plugins/XPlugin/PostNode';

export const INSERT_POST_COMMAND: LexicalCommand<string> = createCommand();

export const XPlugin = (): null => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([PostNode])) {
            throw new Error('XPlugin: PostNode not registered on editor');
        }

        return editor.registerCommand<PostNodeData>(
            INSERT_POST_COMMAND,
            ({ postID }) => {
                const postNode = PostNode.__createNode({ postID, type: CustomNodeTypes.post });
                const paragraph = $createParagraphNode();

                $insertNodes([postNode, paragraph]);
                return true;
            },
            COMMAND_PRIORITY_EDITOR,
        );
    }, [editor]);

    return null;
};
