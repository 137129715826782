import useTranslation from 'next-translate/useTranslation';

import { BaseModalType, useModalContext } from '@admin/organisms/BaseModal';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';

import styles from './InsertPost.module.scss';

interface Props {
    onClick: () => void;
}

export const InsertPost = ({ onClick = () => {} }: Props) => {
    const { openModal } = useModalContext(BaseModalType.Default);
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const handleOnClick = () => {
        openModal();
        onClick();
    };

    return (
        <button
            onClick={handleOnClick}
            className={styles.InsertPost}
            aria-label={__translate('insertPost.button')}
        >
            <Icon.x />
            <span>{__translate('insertPost.button')}</span>
        </button>
    );
};
