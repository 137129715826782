import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, COMMAND_PRIORITY_LOW, KEY_BACKSPACE_COMMAND } from 'lexical';

import { isCustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode/helpers';

interface Props {}

export const RemoveOnBackspacePlugin = ({}: Props) => {
    const [editor] = useLexicalComposerContext();

    useEffect(
        () =>
            editor.registerCommand(
                KEY_BACKSPACE_COMMAND,
                () => {
                    const selection = $getSelection();
                    const nodes = selection?.getNodes();
                    const node = (nodes || [])[0];

                    if (isCustomNode(node)) {
                        node.remove();
                    }

                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        [editor],
    );

    return null;
};
