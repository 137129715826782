import React, { CSSProperties, useContext, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalCommand } from 'lexical';

import { Dropdown, DropdownContext, InlineButton } from '@admin/molecules/RichEditor/Editor/atoms';
import {
    INSERT_MATCH_DETAIL_SECTION_COMMAND,
    MatchDetailSectionModal,
    MatchDetailSectionNodeData,
} from '@admin/molecules/RichEditor/Editor/plugins/MatchDetailSectionPlugin';
import { EmbedPostModal } from '@admin/molecules/RichEditor/Editor/plugins/XPlugin';
import { BaseModalProvider } from '@admin/organisms/BaseModal';
import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import { InsertMatchDetailSection, InsertPost } from './atoms';

import styles from './InlineToolbarPlugin.module.scss';

enum ToolbarOptions {
    insertPost = 'insertPost',
    insertNatchDetailSection = 'insertNatchDetailSection',
}

export const InlineToolbarPlugin = () => {
    const toolbarRef = useRef(null);
    const { isOpen, close, toggle } = useContext(DropdownContext);
    const { platform } = useContextData();
    const [editor] = useLexicalComposerContext();

    const toolbarOptions = {
        [ToolbarOptions.insertPost]: (
            <div className={styles.item}>
                <InsertPost onClick={close} />
            </div>
        ),
        [ToolbarOptions.insertNatchDetailSection]: (
            <div className={styles.item}>
                <InsertMatchDetailSection onClick={close} />
            </div>
        ),
    };

    const platformToolbarOptions = [PlatformID.BR, PlatformID.GP].includes(platform.id)
        ? [ToolbarOptions.insertPost]
        : Object.values(ToolbarOptions);

    const style = { '--dropdownItems': platformToolbarOptions.length } as CSSProperties;

    return (
        <BaseModalProvider>
            <div className={styles.InlineToolbarPlugin} ref={toolbarRef}>
                <div data-theme={platform.id}>
                    <InlineButton onClick={toggle} className={isOpen ? styles.opened : styles.closed}>
                        <svg
                            data-testid="inline-toolbar-plugin"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16 9.6H9.6V16H6.4V9.6H0V6.4H6.4V0H9.6V6.4H16V9.6Z"
                                fill="currentColor"
                            />
                        </svg>
                    </InlineButton>
                </div>
                <div className={styles.position} style={style}>
                    <Dropdown className={styles.dropDown}>
                        <div className={styles.container}>
                            {platformToolbarOptions.map((option, index) => (
                                <div key={index}>{toolbarOptions[option]}</div>
                            ))}
                        </div>
                    </Dropdown>
                </div>
            </div>
            <MatchDetailSectionModal
                onSubmit={(data) =>
                    editor.dispatchCommand(
                        INSERT_MATCH_DETAIL_SECTION_COMMAND as LexicalCommand<MatchDetailSectionNodeData>,
                        data,
                    )
                }
            />
            <EmbedPostModal />
        </BaseModalProvider>
    );
};
